<script>
import { decodeWord, encodeWord } from '@/utils/util.js'
export default {
    name: 'item-component',
    props: {
        index: { // index of current item
            type: Number
        },
        source: { // here is: {uid: 'unique_1', text: 'abc'}
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            kdxfMap: new Map([
                ['black_list', '黑名单纠错'],
                ['pol', '政治术语纠错'],
                ['char', '别字纠错'],
                ['word', '别词纠错'],
                ['redund', '语法纠错-冗余'],
                ['miss', '语法纠错-缺失'],
                ['order', '语法纠错-乱序'],
                ['lx_word', '词级别乱序'],
                ['lx_char', '字级别乱序'],
                ['dapei', '搭配'],
                ['punc', '标点纠错'],
                ['idm', '成语纠错'],
                ['org', '机构名纠错'],
                ['org_R', '机构名字词冗余'],
                ['org_M', '机构名字词缺失'],
                ['org_S', '机构名字词错误'],
                ['org_N', '机构名称变更'],
                ['org_P', '机构名字词乱序'],
                ['leader', '领导人职称纠错'],
                ['number', '数字纠错'],
                ['time', '时间纠错'],
                ['date-m', '日期纠错（月份）'],
                ['date-d', '日期纠错（日）'],
                ['addr', '地名纠错'],
                ['addr_R', '地名字词冗余'],
                ['addr_M', '地名字词缺失'],
                ['addr_S', '地名字词错误'],
                ['name', '全文人名纠错'],
                ['grammar_pc', '句式杂糅&语义重复'],
            ])
        }
    },
    render() {
        console.log({ 'this.source': this.source });
        const { source: { text, onClick, values, target, remark }, kdxfMap } = this
        var targetValue = text[2] || target
        if (values?.length) {
            targetValue = values?.filter(i => text[1] !== i).join(" -> ")
        }
        const type = kdxfMap.get(text[4]) || text[4] || text[3] || kdxfMap.get(text[3])
        return (<p class="inspection-result" vOn:click={onClick}>
            <div class='inspection-result-value'>
                {`${this.index + 1} . ${decodeWord(text[1])}${targetValue ? " -> " + decodeWord(targetValue) : ''}`}
                <span>{type}</span>
            </div>
            {typeof remark === 'string' && (<span class='mix-up-remark'>{remark}</span>)}
        </p>)
    },

}
</script>

<style scoped lang="less">
.inspection-result {
    display: flex;
    background: white;
    flex-direction: column;
    padding: 5px 15px;
    border-bottom: 1px solid #e8e8e8;
    margin: 0;
}
.mix-up-remark {
  white-space: pre-line;
  word-break: break-word;
}
.inspection-result-value {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
}

.inspection-result:last-child {
    border-bottom: none;
}
</style>